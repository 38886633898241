import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import CreateTemplateForm from './CreateTemplateForm';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const TemplateForm = ({onMessageCreate}) => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [variables, setVariables] = useState({});
  const [view, setView] = useState('list');

  useEffect(() => {
    // Fetch templates from your API
    fetchTemplates();
  }, []);
  
  const fetchTemplates = async () => {
    //setIsLoading(true);
    //setError(null);
    const { data, error } = await supabase
        .from('templates')
        .select('*');
    if (error) {
        console.error('Error fetching blood tests:', error);
        //setError('Failed to fetch blood test results');
    } else {
        setTemplates(data);
    }
    //setIsLoading(false);
  };

  const handleTemplateChange = (e) => {
    const template = templates.find(t => t.sid === e.target.value);
    setSelectedTemplate(template);
    setVariables({});
  };

  const handleVariableChange = (key, value) => {
    setVariables(prevVars => ({
      ...prevVars,
      [key]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedTemplate) return;
    onMessageCreate({
        templateSid: selectedTemplate.sid,
        variables: variables,
        templateContent: selectedTemplate.content
    })
  };

  const onTemplateCreate = () => {
    setView('list')
    fetchTemplates()
  }

  const renderTemplateContent = () => {
    if (!selectedTemplate) return null;

    const parts = selectedTemplate.content.split(/(\{\{\d+\}\})/);
    return parts.map((part, index) => {
      const match = part.match(/\{\{(\d+)\}\}/);
      if (match) {
        const varKey = match[1];
        return (
          <input
            key={index}
            type="text"
            value={variables[varKey] || ''}
            onChange={(e) => handleVariableChange(varKey, e.target.value)}
            placeholder={`Variable ${varKey}`}
            style={{ width: '120px', margin: '0 5px' }}
          />
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  return (
    <>
        {view=='create' && <CreateTemplateForm onCreate={() => onTemplateCreate()} templates={templates} />}
        {view=='list' && <><button onClick={() => setView('create')}>Add a new whatsapp message template</button>
        <form onSubmit={handleSubmit}>
        <select onChange={handleTemplateChange} value={selectedTemplate?.sid || ''}>
            <option value="">Select a template</option>
            {templates.map(template => (
            <option key={template.sid} value={template.sid}>
                {template.name}
            </option>
            ))}
        </select>

        {selectedTemplate && (
            <div>
            <h3>{selectedTemplate.name}</h3>
            <div style={{ marginBottom: '20px' }}>
                {renderTemplateContent()}
            </div>
            <button type="submit">Send Template</button>
            </div>
        )}
        </form>
        </>}
    </>
  );
};

export default TemplateForm;