import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import AutoResizeTextarea from './AutoResizeTextarea';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

function ExamplesPopup({ onClose, initialFlow='' }) {
  const [examples, setExamples] = useState([]);
  const [newExample, setNewExample] = useState({ input: '', output: '', flow: initialFlow });
  const [editingExample, setEditingExample] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState(initialFlow);
  /*
  useEffect(() => {
    fetchExamples();
  }, []);
  */
  useEffect(() => {
    fetchExamples();
  }, [filter]);

  async function fetchExamples() {
    setIsLoading(true);
    let query = supabase
      .from('examples')
      .select('*')
      .order('created_at', { ascending: false });
    
    if (filter) {
      query = query.eq('flow', filter);
    }

    const { data, error } = await query;

    if (error) {
      console.error('Error fetching examples:', error);
    } else {
      setExamples(data);
    }
    setIsLoading(false);
  }


  async function addExample() {
    if (!newExample.input.trim() || !newExample.output.trim()) return;

    setIsLoading(true);
    const { data, error } = await supabase
      .from('examples')
      .insert(newExample)
      .select();

    if (error) {
      console.error('Error adding example:', error);
    } else if (data) {
      setExamples(prevExamples => [data[0], ...prevExamples]);
      setNewExample({ input: '', output: '', flow: '' });
    }
    setIsLoading(false);
  }

  async function updateExample() {
    if (!editingExample || !editingExample.input.trim() || !editingExample.output.trim()) return;

    setIsLoading(true);
    const { data, error } = await supabase
      .from('examples')
      .update(editingExample)
      .eq('id', editingExample.id)
      .select();

    if (error) {
      console.error('Error updating example:', error);
    } else if (data) {
      setExamples(prevExamples =>
        prevExamples.map(example =>
          example.id === editingExample.id ? data[0] : example
        )
      );
      setEditingExample(null);
    }
    setIsLoading(false);
  }

  function handleEdit(example) {
    setEditingExample(example);
  }

  function handleCancelEdit() {
    setEditingExample(null);
  }

  return (
    <div className="examples-popup">
      <div className="examples-popup-content">
        <button className="close-button" onClick={onClose}>X</button>
        <div className='content-container'>
          <div className='col-left'>            
            <h2>Examples {filter ? `for ${filter}` : ''}</h2>
            
            <div className="filter-section">
              <input
                type="text"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Filter by flow"
              />
              <button onClick={() => setFilter('')}>Clear Filter</button>
            </div>

            <div className="add-example">
              <h3>Add New Example</h3>
              <AutoResizeTextarea
                value={newExample.input}
                onChange={(e) => setNewExample({...newExample, input: e.target.value, complete_chat_example: ''})}
                placeholder="Input"
                style={{'width':'100%'}}
              />
              <br />
              <AutoResizeTextarea
                value={newExample.output}
                onChange={(e) => setNewExample({...newExample, output: e.target.value, complete_chat_example: ''})}
                placeholder="Output"
                style={{'width':'100%'}}
              />
              <br />
              <AutoResizeTextarea
                value={newExample.complete_chat_example}
                onChange={(e) => setNewExample({...newExample, complete_chat_example: e.target.value, input: '', output: ''})}
                placeholder="Else provide a complete chat example instead of input and output"
                style={{'width':'100%'}}
              />
              <input
                type="text"
                value={newExample.flow}
                onChange={(e) => setNewExample({...newExample, flow: e.target.value})}
                placeholder="Flow (optional)"
              />
              <button onClick={addExample} disabled={isLoading}>
                {isLoading ? 'Adding...' : 'Add Example'}
              </button>
            </div>
          </div>
          <div className='col-right'>
            <div className="examples-list">
              {examples.map((example) => (
                <div key={example.id} className="example-item">
                  {editingExample && editingExample.id === example.id ? (
                    <div className="edit-example">
                      <AutoResizeTextarea
                        value={editingExample.input}
                        onChange={(e) => setEditingExample({...editingExample, input: e.target.value, complete_chat_example: '' })}
                        placeholder="Input"
                        style={{'width':'100%'}}
                      />
                      <br />
                      <AutoResizeTextarea
                        value={editingExample.output}
                        onChange={(e) => setEditingExample({...editingExample, output: e.target.value, complete_chat_example: ''})}
                        placeholder="Output"
                        style={{'width':'100%'}}
                      />
                      <br />
                      <AutoResizeTextarea
                        value={editingExample.complete_chat_example}
                        onChange={(e) => setEditingExample({...editingExample, complete_chat_example: e.target.value, input: '', output: ''})}
                        placeholder="Else provide a complete chat example instead of input and output"
                        style={{'width':'100%'}}
                      />
                      <input
                        type="text"
                        value={editingExample.flow}
                        onChange={(e) => setEditingExample({...editingExample, flow: e.target.value})}
                      />
                      <button onClick={updateExample} disabled={isLoading}>
                        {isLoading ? 'Updating...' : 'Update'}
                      </button>
                      <button onClick={handleCancelEdit}>Cancel</button>
                    </div>
                  ) : (
                    <>
                      <p><strong>Input:</strong> {example.input}</p>
                      <p><strong>Output:</strong> {example.output}</p>
                      {example.flow && <p><strong>Flow:</strong> {example.flow}</p>}
                      <p><small>Created at: {new Date(example.created_at).toLocaleString()}</small></p>
                      <button onClick={() => handleEdit(example)}>Edit</button>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExamplesPopup;