import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import AutoResizeTextarea from './AutoResizeTextarea';
import ExamplesPopup from './ExamplesPopup';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

function FeedbackList({ conversationId, onFeedbackAdded, selectedMessage, inFunction, selectedHumanMessage='' }) {
  const [feedbacks, setFeedbacks] = useState([]);
  const [newFeedback, setNewFeedback] = useState('');
  const [newThoughts, setNewThoughts] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [newInFunction, setNewInFunction] = useState(null);
  const [isToExampleFormOpen, setIsToExampleFormOpen] = useState(false);  
  const [showExamplesPopup, setShowExamplesPopup] = useState(false);

  useEffect(() => {
    fetchFeedbacks();
    getCurrentUser();
  }, [conversationId]);
  
  useEffect(() => {
    if (selectedMessage) {
      setNewFeedback(selectedMessage);
      setNewThoughts('');
      setNewInFunction(inFunction);
    }
  }, [selectedMessage, inFunction]);

  async function getCurrentUser() {
    const { data: { user } } = await supabase.auth.getUser();
    setCurrentUser(user);
  }

  async function fetchFeedbacks() {
    setIsLoading(true);
    const { data, error } = await supabase
      .from('conversation_feedbacks')
      .select('*')
      .eq('conversation_history_id', conversationId)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching feedbacks:', error);
    } else {
      setFeedbacks(data);
    }
    setIsLoading(false);
  }

  async function addFeedback() {
    console.log('adding feedback',newFeedback, newThoughts, conversationId)
    if ((!newFeedback.trim() && !newThoughts.trim()) || !currentUser) return;
    console.log('adding feedback after trim',newFeedback, newThoughts, conversationId)

    setIsLoading(true);
    const { data, error } = await supabase
      .from('conversation_feedbacks')
      .insert({
        conversation_history_id: conversationId,
        feedback: newFeedback,
        thoughts: newThoughts,
        author_id: currentUser.id
      })
      .select();

    if (error) {
      console.error('Error adding feedback:', error);
    } else if (data) {
      setFeedbacks(prevFeedbacks => [data[0], ...prevFeedbacks]);
      setNewFeedback('');
      setNewThoughts('');
      onFeedbackAdded();
    }
    setIsLoading(false);
  }

  async function convertToExample(feedbackId, feedbackText) {
    setIsLoading(true);
    
    // First, insert into examples table
    const { data: exampleData, error: exampleError } = await supabase
      .from('examples')
      .insert({
        input: selectedHumanMessage,
        output: feedbackText,
        flow: newInFunction
      })
      .select();

    if (exampleError) {
      console.error('Error creating example:', exampleError);
      setIsLoading(false);
      return;
    }

    // Then, update the conversation_feedbacks table with the new example_id
    const { error: updateError } = await supabase
      .from('conversation_feedbacks')
      .update({ example_id: exampleData[0].id })
      .eq('id', feedbackId);

    if (updateError) {
      console.error('Error updating feedback with example ID:', updateError);
    } else {
      // Update the local state to reflect the change
      setFeedbacks(prevFeedbacks => 
        prevFeedbacks.map(feedback => 
          feedback.id === feedbackId 
            ? { ...feedback, example_id: exampleData[0].id } 
            : feedback
        )
      );
    }

    setIsLoading(false);
    setIsToExampleFormOpen(false)
  }

  return (
    <div className="feedback-list">      
      {inFunction && (
        <a onClick={() => setShowExamplesPopup(true)}>
          View all examples for {inFunction} flow.
        </a>
      )}
      <h3>Feedbacks</h3>
      <div className="add-feedback">
        <AutoResizeTextarea
          value={newFeedback}
          onChange={(e) => setNewFeedback(e.target.value)}
          placeholder="Add your feedback"
        />
        <AutoResizeTextarea
          value={newThoughts}
          onChange={(e) => setNewThoughts(e.target.value)}
          placeholder="Additional thoughts (optional)"
        />
        <button onClick={addFeedback} disabled={isLoading}>
          {isLoading ? 'Adding...' : 'Add Feedback'}
        </button>
      </div>
      {isLoading && <p>Loading...</p>}
      {feedbacks.map((feedback) => (
        <div key={feedback.id} className="feedback-item">
          <p><strong>Feedback:</strong> {feedback.feedback}</p>
          {feedback.thoughts && <p><strong>Thoughts:</strong> {feedback.thoughts}</p>}
          <p><small>Added on: {new Date(feedback.created_at).toLocaleString()}</small></p>
          {feedback.feedback && (
            <>
              {!feedback.example_id && (
                <>
                {!isToExampleFormOpen &&
                <button 
                  onClick={() => setIsToExampleFormOpen(true)}
                  disabled={isLoading}
                >
                  Convert to Example
                </button>}
                {isToExampleFormOpen && 
                <>
                Flow:
                <br />
                <input type='text' value={newInFunction} onChange={(e) => setNewInFunction(e.target.value)} />
                <br />
                Flows are like: diagnosis, greetings, identity etc.
                <br />
                <button 
                  onClick={() => convertToExample(feedback.id, feedback.feedback)}
                  disabled={isLoading}
                >
                  Add to examples
                </button>
                </>}
                </>
              )}
            </>
          )}
          {feedback.example_id && <p>Converted to Example ID: {feedback.example_id}</p>}
        </div>
      ))}
      {showExamplesPopup && (
        <ExamplesPopup 
          onClose={() => setShowExamplesPopup(false)} 
          initialFlow={inFunction}
        />
      )}
    </div>
  );
}

export default FeedbackList;