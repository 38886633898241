import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import './App.css'; // Make sure to create this file for the styles
import MessageWithFeedback from './components/MessageWithFeedback';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import FeedbackList from './components/FeedbackList';
import ExamplesPopup from './components/ExamplesPopup';
import AutoResizeTextarea from './components/AutoResizeTextarea';
import BloodTestResults from './components/BloodTestResults';
import TemplateForm from './components/TemplateForm';
import Mapping from './components/Mapping';
import CsvMappingApp from './components/CsvMappingApp';

// Initialize Supabase client
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY
const backend_base_url = process.env.REACT_APP_BACKEND_URL
const base_url = process.env.REACT_APP_URL
const supabase = createClient(supabaseUrl, supabaseKey)

function App() {
  const [session, setSession] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDM, setLoadingDM] = useState(false);
  const [showExamplesPopup, setShowExamplesPopup] = useState(false);
  const [showMappingPopup, setShowMappingPopup] = useState(false);
  const [feedbackCounts, setFeedbackCounts] = useState({});
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedHumanMessage, setSelectedHumanMessage] = useState(null);
  const [selectedInFunction, setSelectedInFunction] = useState(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [messageToSend, setMessageToSend] = useState(null);
  const [response, setResponse] = useState(null);
  
  // Update the handleMessageClick function
  function handleMessageClick(conversationId, messageContent, inFunction, theHumanMessage='') {
    setSelectedConversation(conversationId);
    setSelectedMessage(messageContent);
    setSelectedHumanMessage(theHumanMessage)
    setSelectedInFunction(inFunction);
  }

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (session) {
      fetchUsers();
    }
  }, [session]);

  async function fetchUsers() {
    const { data, error } = await supabase
      .from('users')
      .select('*');
    
    if (error) {
      console.error('Error fetching users:', error);
    } else {
      setUsers(data);
    }
  }  
  async function fetchConversations(userId) {
    setLoading(true);
    const { data, error } = await supabase
      .from('conversation_history')
      .select('id, conversation, in_function, code_version, is_system_generated')
      .eq('user_id', userId)
      .order('created_at', { ascending: true });
    
    if (error) {
      console.error('Error fetching conversations:', error);
    } else {
      const parsedConversations = data.map(item => ({
        id: item.id,
        messages: parseConversation(item.conversation),
        current_function: item.in_function,
        code_version: item.code_version,
        is_system_generated: item.is_system_generated
      }));
      setConversations(parsedConversations);
      fetchFeedbackCounts(parsedConversations.map(conv => conv.id));
    }
    setLoading(false);
  }

  async function fetchFeedbackCounts(conversationIds) {
    const { data, error } = await supabase
      .from('conversation_feedbacks')
      .select('conversation_history_id')
      .in('conversation_history_id', conversationIds);
  
    if (error) {
      console.error('Error fetching feedback counts:', error);
    } else {
      const counts = {};
      data.forEach(item => {
        counts[item.conversation_history_id] = (counts[item.conversation_history_id] || 0) + 1;
      });
      setFeedbackCounts(counts);
    }
  }

  function parseConversationOld(conversationString) {
    const parts = conversationString.split(/Human: |Assistant: /);
    parts.shift(); // Remove the first empty element
    
    const messages = [];
    for (let i = 0; i < parts.length; i += 2) {
      const humanMessage = parts[i].trim();
      const assistantParts = parts[i + 1]?.split(' at ');
      const assistantMessage = assistantParts?.[0]?.trim();
      const timestamp = assistantParts?.[1]?.trim();

      messages.push({ type: 'human', text: humanMessage });
      if (assistantMessage) {
        messages.push({ type: 'assistant', text: assistantMessage, timestamp });
      }
    }
    return messages;
  }
  function removeTrailingComma(str) {
    return str.replace(/,\s*$/, '');
  }
  function parseConversation(conversationString) {
    const parts = conversationString.split(/Human: |Assistant: /);
    parts.shift(); // Remove the first empty element
    
    const messages = [];
    for (let i = 0; i < parts.length; i += 2) {
      const humanMessage = parts[i].trim();
      messages.push({ type: 'human', text: humanMessage, humanMessage: humanMessage });
  
      if (i + 1 < parts.length) {
        const assistantFullMessage = parts[i + 1].trim();
        const lastAtIndex = assistantFullMessage.lastIndexOf(' at ');
        
        if (lastAtIndex !== -1) {
          const assistantMessage = assistantFullMessage.substring(0, lastAtIndex).trim();
          const timestamp = assistantFullMessage.substring(lastAtIndex + 4).trim();
          messages.push({ type: 'assistant', text: removeTrailingComma(assistantMessage), humanMessage: humanMessage, timestamp });
        } else {
          messages.push({ type: 'assistant', text: removeTrailingComma(assistantFullMessage), humanMessage: humanMessage });
        }
      }
    }
    return messages;
  }
  async function addFeedback(conversationHistoryId, feedback, thoughts) {
    const { data, error } = await supabase
      .from('conversation_feedbacks')
      .insert({
        conversation_history_id: conversationHistoryId,
        feedback,
        thoughts,
        author_id: session.user.id
      });
  
    if (error) {
      console.error('Error adding feedback:', error);
    } else {
      // Update the feedback count
      setFeedbackCounts(prev => ({
        ...prev,
        [conversationHistoryId]: (prev[conversationHistoryId] || 0) + 1
      }));
    }
  }

  function handleUserClick(user) {
    setSelectedUser(user);
    fetchConversations(user.auth_id);
  }

  const sendDirectMessage = async () => {
    setResponse(null);
    setLoadingDM(true)
    try {
      const result = await fetch(`${backend_base_url}/sendmessagestouser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "user_id": selectedUser.auth_id, "the_message": messageToSend }),
      });
      
      const responseData = await result.json();
      setResponse(responseData.response);
      setLoadingDM(false)
      setMessageToSend(null)
    } catch (error) {
      setResponse('Error: ' + error.message);
      setLoadingDM(false)
    }
  };

  const sendDirectMessageTemplate = async ({templateSid, variables, templateContent}) => {
    setResponse(null);
    setLoadingDM(true)
    try {
      const result = await fetch(`${backend_base_url}/sendmessagestouser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({"user_id": selectedUser.auth_id, "templateSid": templateSid, "variables": variables, "templateContent": templateContent}),
      });
      
      const responseData = await result.json();
      setResponse(responseData.response);
      setLoadingDM(false)
      setMessageToSend(null)
    } catch (error) {
      setResponse('Error: ' + error.message);
      setLoadingDM(false)
    }
  };
  
  if (!session) {
    return (
      <div className="auth-container">
        <Auth
          supabaseClient={supabase}
          appearance={{ theme: ThemeSupa }}
          providers={[]}
          view='magic_link'
          redirectTo={base_url}
          showLinks={false}
        />
      </div>
    );
  }
  function handleFeedbackAdded(conversationId) {
    setFeedbackCounts(prevCounts => ({
      ...prevCounts,
      [conversationId]: (prevCounts[conversationId] || 0) + 1
    }));
  }

  //const userCount = users.length

  return (
    <div className="app-container">
      <h1>User Conversations</h1>
      <button onClick={() => setShowExamplesPopup(true)}>Open Examples</button>
      <button onClick={() => setShowMappingPopup(true)}>Open Mapping</button>
      <button onClick={() => setIsSuperAdmin(!isSuperAdmin)}>A</button>
      {showExamplesPopup && (
        <ExamplesPopup onClose={() => setShowExamplesPopup(false)} />
      )}
      {showMappingPopup && (
        <CsvMappingApp onClose={() => setShowMappingPopup(false)} />
      )}
      <button className="close-button" onClick={() => supabase.auth.signOut()}>Sign Out</button>
      <div className="content-container">
        <div className="user-list">
          <h2>Users ({users && users.length})</h2>
          <div className="user-list-box">
            <ul>
              {users.map(user => (
                <li 
                  key={user.id} 
                  onClick={() => handleUserClick(user)}
                  className={selectedUser?.id === user.id ? 'selected' : ''}
                >
                  {!isSuperAdmin && user.id}
                  {isSuperAdmin && <>{user.name} {user.phone_number}</>}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="conversation-container">
          <h2>Conversations {(selectedUser && isSuperAdmin) && <a href={`https://mixpanel.com/project/3323156/view/3827496/app/profile#distinct_id=${selectedUser.auth_id}`} target='_blank'>MP</a>}</h2>
          {selectedUser && <>            
            <AutoResizeTextarea
              value={messageToSend}
              onChange={(e) => setMessageToSend(e.target.value)}
              placeholder="Send message to the user directly"
              style={{"width": "100%"}}
            />
            <button onClick={sendDirectMessage} disabled={loadingDM}>
              {loadingDM ? 'Sending...' : 'Send whatsapp message'}
            </button>
            <TemplateForm onMessageCreate={(e) => sendDirectMessageTemplate(e)} />
            {response}
          </>}
          {selectedUser ? (
            loading ? (
              <p>Loading conversations...</p>
            ) : conversations.length > 0 ? (
              <div className="chat-interface">
                {conversations.map((conversation, index) => (
                  <div key={index} className={(selectedConversation==conversation.id) ? 'conversation bg-light' : 'conversation'}>
                    {conversation.messages.map((message, msgIndex) => (
                        <MessageWithFeedback
                          key={msgIndex}
                          message={{ ...message, id: conversation.id }}
                          onAddFeedback={addFeedback}
                          onClick={() => handleMessageClick(conversation.id, message.text, conversation.current_function, message.humanMessage)}
                          feedbackCount={feedbackCounts[conversation.id] || 0}
                          presentFunction={conversation.current_function}
                          presentCodeVersion={conversation.code_version}
                          isSystemGenerated={conversation.is_system_generated}
                          humanMessage={message.humanMessage}
                        />
                    ))}
                  </div>
                ))}
              </div>
            ) : (
              <p>No conversations found for this user.</p>
            )
          ) : (
            <p>Select a user to view their conversations.</p>
          )}
        </div>
        {selectedConversation && (
          <div className="feedback-container">
            <FeedbackList 
              conversationId={selectedConversation} 
              onFeedbackAdded={() => handleFeedbackAdded(selectedConversation)}  
              inFunction={selectedInFunction}            
              selectedMessage={selectedMessage}
              selectedHumanMessage={selectedHumanMessage}
            />
            
          </div>
        )}
        {selectedUser && <BloodTestResults userId={selectedUser.auth_id} />}
      </div>
    </div>
  );
}

export default App;