import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import Select from 'react-select';
import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client using environment variables
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const CsvMappingApp = ({ onClose }) => {
  const [file, setFile] = useState(null);
  const [mapping, setMapping] = useState({});
  const [combinedData, setCombinedData] = useState([]);
  const [internalBiomarkers, setInternalBiomarkers] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [existingMappings, setExistingMappings] = useState({});

  useEffect(() => {
    fetchExistingMappings();
    fetchInternalBiomarkers();
  }, []);

  useEffect(() => {
    if (file) {
      autoMatchBiomarkers();
    }
  }, [file, existingMappings, internalBiomarkers]);

  const fetchExistingMappings = async () => {
    const { data, error } = await supabase
      .from('biomarker_mappings')
      .select('*');

    if (error) {
      console.error('Error fetching existing mappings:', error);
      return;
    }

    const mappings = data.reduce((acc, item) => {
      acc[item.uploaded_biomarker] = {
        value: item.internal_biomarker,
        key_internal: item.key_internal
      };
      return acc;
    }, {});

    setExistingMappings(mappings);
  };

  const fetchInternalBiomarkers = async () => {
    const { data, error } = await supabase
      .from('scoring_logic')
      .select('biomarker, biomarker_key');

    if (error) {
      console.error('Error fetching internal biomarkers:', error);
      return;
    }

    const biomarkers = data.map(item => ({
      value: item.biomarker,
      label: item.biomarker,
      key_internal: item.biomarker_key
    }));

    setInternalBiomarkers(biomarkers);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFile(file);
    extractUploadedBiomarkers(file);
  };

  const extractUploadedBiomarkers = (file) => {
    Papa.parse(file, {
      complete: (results) => {
        const headers = results.data[0];
        const newMapping = headers.reduce((acc, header) => {
          acc[header] = existingMappings[header] || { value: '', key_internal: '' };
          return acc;
        }, {});
        setMapping(newMapping);
      },
    });
  };

  const autoMatchBiomarkers = () => {
    const matchedMapping = {};
    Object.keys(mapping).forEach((uploadedBiomarker) => {
      if (existingMappings[uploadedBiomarker]) {
        matchedMapping[uploadedBiomarker] = existingMappings[uploadedBiomarker];
      } else {
        const match = internalBiomarkers.find(
          (biomarker) => biomarker.label.toLowerCase() === uploadedBiomarker.toLowerCase()
        );
        matchedMapping[uploadedBiomarker] = match 
          ? { value: match.value, key_internal: match.key_internal }
          : { value: '', key_internal: '' };
      }
    });
    setMapping(matchedMapping);
  };

  const handleMappingChange = async (selectedOption, uploadedBiomarker) => {
    const newValue = selectedOption ? selectedOption.value : '';
    const newKeyInternal = selectedOption ? selectedOption.key_internal : '';
    
    // Check if mapping already exists in Supabase
    const { data, error } = await supabase
      .from('biomarker_mappings')
      .select('*')
      .eq('uploaded_biomarker', uploadedBiomarker)
      .single();

    if (error && error.code !== 'PGRST116') {
      console.error('Error checking existing mapping:', error);
      return;
    }

    if (data) {
      // Mapping exists, update it
      updateMapping(uploadedBiomarker, newValue, newKeyInternal);
    } else {
      // No existing mapping, create new one
      createMapping(uploadedBiomarker, newValue, newKeyInternal);
    }

    setMapping((prevMapping) => ({
      ...prevMapping,
      [uploadedBiomarker]: { value: newValue, key_internal: newKeyInternal },
    }));
  };

  const createMapping = async (uploadedBiomarker, internalBiomarker, keyInternal) => {
    const { error } = await supabase
      .from('biomarker_mappings')
      .insert({ 
        uploaded_biomarker: uploadedBiomarker, 
        internal_biomarker: internalBiomarker,
        key_internal: keyInternal
      });

    if (error) {
      console.error('Error creating mapping:', error);
    }
  };

  const updateMapping = async (uploadedBiomarker, internalBiomarker, keyInternal) => {
    const { error } = await supabase
      .from('biomarker_mappings')
      .update({ 
        internal_biomarker: internalBiomarker,
        key_internal: keyInternal
      })
      .eq('uploaded_biomarker', uploadedBiomarker);

    if (error) {
      console.error('Error updating mapping:', error);
    }
  };

  const combineCsvData = () => {
    if (!file) return;

    Papa.parse(file, {
      complete: (results) => {
        const headers = results.data[0];
        const combinedHeaders = ['biomarker_uploaded', 'biomarker_internal', 'key_internal'];

        const combinedRows = headers.map((header) => {
          const mappedData = mapping[header] || { value: '', key_internal: '' };
          return [header, mappedData.value, mappedData.key_internal];
        });

        setCombinedData([combinedHeaders, ...combinedRows]);
      },
    });
  };

  const downloadCsv = () => {
    const csv = Papa.unparse(combinedData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'combined_biomarkers.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  
  const tableStyles = {
    borderCollapse: 'collapse',
    width: '100%',
  };

  const cellStyles = {
    border: '1px solid #ddd',
    padding: '8px',
  };

  const headerCellStyles = {
    ...cellStyles,
    backgroundColor: '#f2f2f2',
    fontWeight: 'bold',
  };
  return (
    <div>
      <h2>CSV Mapping App</h2>
      <button className="close-button" onClick={onClose}>
        X
      </button>
      <div>
        <h3>Upload CSV File</h3>
        <div>
          <label>Upload uncleaned CSV:</label>
          <input type="file" onChange={handleFileUpload} />
        </div>
      </div>
      {alertMessage && (
        <div className="alert">
          {alertMessage}
          <button onClick={() => setAlertMessage('')}>Close</button>
        </div>
      )}
      {file && (
        <div>
          <h3>Mapping</h3>
          <table style={tableStyles}>
            <thead>
              <tr>
                <th style={headerCellStyles}>Uploaded Biomarker</th>
                <th style={headerCellStyles}>Internal Biomarker</th>
                <th style={headerCellStyles}>Key Internal</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(mapping).map(([key, mappedValue]) => (
                <tr key={key}>
                  <td style={cellStyles}>{key}</td>
                  <td style={cellStyles}>
                    <Select
                      value={
                        mappedValue.value ? 
                        { 
                          value: mappedValue.value, 
                          label: internalBiomarkers.find(b => b.value === mappedValue.value)?.label || mappedValue.value,
                          key_internal: mappedValue.key_internal
                        } : null
                      }
                      onChange={(selectedOption) => handleMappingChange(selectedOption, key)}
                      options={internalBiomarkers}
                      isClearable
                    />
                  </td>
                  <td style={cellStyles}>
                    {mappedValue.key_internal || ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={combineCsvData}>Combine CSV Data</button>
        </div>
      )}
      {combinedData.length > 0 && (
        <div>
          <h3>Combined Data</h3>
          <table style={tableStyles}>
            <thead>
              <tr>
                {combinedData[0].map((header, index) => (
                  <th key={index} style={headerCellStyles}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {combinedData.slice(1).map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex} style={cellStyles}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={downloadCsv}>Download CSV</button>
        </div>
      )}
    </div>
  );
};

export default CsvMappingApp;