import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { generateSignedUrl } from '../utils/spbs';
//import { generateSignedUrl } from '../utils/spbs';
//import getSignedUrl from './utils/spbs.js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

function BloodTestResults({ userId }) {
  const [bloodTests, setBloodTests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [signedUrls, setSignedUrls] = useState({});


  useEffect(() => {
    fetchBloodTests();
  }, [userId]);

  async function fetchBloodTests() {
    setIsLoading(true);
    setError(null);

    const { data, error } = await supabase
      .from('blood_reports')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: false })
      .limit(1);

    if (error) {
      console.error('Error fetching blood tests:', error);
      setError('Failed to fetch blood test results');
    } else {
      setBloodTests(data);
      
      // Generate signed URLs for each test's copy_url
      const urls = {};
      for (const test of data) {
        if (test.copy_url) {
          urls[test.id] = await generateSignedUrl(supabase, test.copy_url);
        }
      }
      setSignedUrls(urls);
    }

    setIsLoading(false);
  }

  function renderBiomarkers(biomarkers) {
    if (!biomarkers || Object.keys(biomarkers).length === 0) {
      return <p>No biomarkers data available</p>;
    }
    const biomarkersArray = JSON.parse(biomarkers);

    return (
      <table className="biomarkers-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Biomarker</th>
            <th>Value</th>
            <th>Unit</th>
            <th>Optimal Range</th>
            <th>Valid Till</th>
          </tr>
        </thead>
        <tbody>
          {biomarkersArray.map((biomarker, key) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{biomarker.biomarker}</td>
              <td>{biomarker.value}</td>
              <td>{biomarker.unit}</td>
              <td>{biomarker.optimal_range}</td>
              <td>{biomarker.marker_valid_till}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  if (isLoading) return <div>Loading blood test results...</div>;
  if (error) return <div>Error: {error}</div>;
  return (
    <div className="blood-test-results">
      <h2>Blood Test Results</h2>
      {bloodTests.length === 0 ? (
        <p>No blood test results found for this user.</p>
      ) : (
        bloodTests.map((test, index) => (
          <div key={test.id} className="blood-test">
            <h3>Test {index + 1}</h3>
            {test.copy_url && signedUrls[test.id] && (
              <p>Uploaded File: <a href={signedUrls[test.id]} target='_blank' rel="noopener noreferrer">Check file</a></p>
            )}            <p>Reported Date: {new Date(test.report_date).toLocaleDateString()}</p>
            <p>Upload Date: {new Date(test.created_at).toLocaleDateString()}</p>
            {renderBiomarkers(test.all_biomarkers)}
          </div>
        ))
      )}
    </div>
  );
}

export default BloodTestResults;