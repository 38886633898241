import React, { useState } from 'react';
import AutoResizeTextarea from './AutoResizeTextarea';

function MessageWithFeedback({ message, presentFunction=false, onAddFeedback, onClick, feedbackCount, presentCodeVersion, isSystemGenerated=false }) {
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [thoughts, setThoughts] = useState('');

  const handleSubmitFeedback = () => {
    onAddFeedback(message.id, feedback, thoughts);
    setShowFeedbackForm(false);
    setFeedback('');
    setThoughts('');
  };

  const timeToIST = (myTime) => {
    const utcTime = new Date(myTime);
    const istTime = new Date(utcTime.getTime() + (5.5 * 60 * 60 * 1000));
    return (
      <>{istTime.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</>
    )
  }

  return (
    <div className={`message ${isSystemGenerated ? 'assistant' : message.type}`} onClick={onClick}>
      <div className="message-content">
        <strong>{(message.type === 'human' && !isSystemGenerated) ? 'Human:' : 'Assistant:'}</strong> {message.text}
      </div>
      
      {/*conversation.map((message, msgIndex) => (
                      <div key={msgIndex} className={`message ${message.type}`}>
                        <div className="message-content">
                          <strong>{message.type === 'human' ? 'Human:' : 'Assistant:'}</strong> {message.text}
                        </div>
                        {message.timestamp && <div className="timestamp">{message.timestamp}</div>}
                      </div>
        ))*/}
      {message.timestamp && <div className="timestamp">{timeToIST(message.timestamp)}</div>}
      {presentFunction && <div className="timestamp">Function: {presentFunction}</div>}
      {presentCodeVersion && <div className="timestamp">Code version: {presentCodeVersion}</div>}
      {isSystemGenerated && <div className="timestamp">Extra: System Generated One</div>}

      {message.type === 'assistant' && (
        <div className="feedback-section">           
          {/*<button onClick={() => setShowFeedbackForm(!showFeedbackForm)}>
            {showFeedbackForm ? 'Cancel Feedback' : 'Add Feedback'}
          </button>*/}
          <span className="feedback-count">Feedbacks: {feedbackCount}</span>
          {showFeedbackForm && (
            <div className="feedback-form">
              <AutoResizeTextarea
                placeholder="Feedback"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
              <AutoResizeTextarea
                placeholder="Thoughts"
                value={thoughts}
                onChange={(e) => setThoughts(e.target.value)}
              />
              <button onClick={handleSubmitFeedback}>Submit Feedback</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default MessageWithFeedback;