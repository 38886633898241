import React, { useRef, useEffect } from 'react';

const AutoResizeTextarea = ({ onChange, ...props }) => {
  const textareaRef = useRef(null);

  const resizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    resizeTextarea();
  }, []);

  const handleChange = (event) => {
    resizeTextarea();
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <textarea
      {...props}
      ref={textareaRef}
      onChange={handleChange}
      style={{ overflow: 'hidden', ...props.style }}
    />
  );
};

export default AutoResizeTextarea;