import React, { useState } from 'react';
import AutoResizeTextarea from './AutoResizeTextarea';

const backend_base_url = process.env.REACT_APP_BACKEND_URL
const base_url = process.env.REACT_APP_URL

const CreateTemplateForm = ({onCreate, templates}) => {
  const [templateName, setTemplateName] = useState('');
  const [content, setContent] = useState('');
  const [language, setLanguage] = useState('en');
  const [variables, setVariables] = useState([]);
  const [quickReplies, setQuickReplies] = useState([]);
  const [rejectionReasons, setRejectionReasons] = useState([]);

  const validateTemplate = (name, text) => {
    const reasons = [];

    // Check for duplicate template name
    if (templates.some(template => template.name.toLowerCase() === name.toLowerCase())) {
      reasons.push("Template name already exists. Please choose a different name.");
    }

    // Check for variables at the beginning or end
    if (/^\{\{[1-9]\}\}/.test(text) || /\{\{[1-9]\}\}$/.test(text)) {
      reasons.push("Variables are placed at the very beginning or end of the message. Add additional words or punctuation before/after the variable.");
    }

    // Check for adjacent variables
    if (/\{\{[1-9]\}\}\s*\{\{[1-9]\}\}/.test(text)) {
      reasons.push("Variables are placed next to each other. Make sure there is at least one word in between each variable.");
    }

    // Check for non-sequential variables
    const variables = text.match(/\{\{(\d+)\}\}/g);
    if (variables) {
      const numbers = variables.map(v => parseInt(v.match(/\d+/)[0]));
      for (let i = 1; i <= numbers.length; i++) {
        if (!numbers.includes(i)) {
          reasons.push("Variable parameters are not sequential. Double check that all placeholders are sequential.");
          break;
        }
      }
    }

    // Check for newlines, tabs, or more than 4 consecutive spaces
    if (/[\n\t]|\s{5,}/.test(text)) {
      reasons.push("Newlines, tabs, or more than 4 consecutive spaces are not allowed.");
    }

    // Check for direct WhatsApp links
    if (/https?:\/\/wa\.me/.test(text)) {
      reasons.push("Call-to-action has a direct link to WhatsApp. Spell out the number without including the link.");
    }

    // Check for potentially sensitive content
    if (/\b(card number|account number|national id|ssn)\b/i.test(text)) {
      reasons.push("Template appears to request sensitive identifiers from users. If you need to request sensitive identifiers, ask for partial identifiers only.");
    }

    // Check for gaming/gambling related content
    if (/\b(raffle|win a prize|lottery|jackpot)\b/i.test(text)) {
      reasons.push("Template appears to be related to gaming or gambling. Identify any words that could be perceived as related to gaming/gambling, and replace them.");
    }

    // Check for vague content
    if (text.length < 20) {
      reasons.push("Template appears to be overly vague. Surround the parameters with information that clarifies what type of information will be inserted. Add more than 20 character");
    }

    return reasons;
  };

  const handleAddVariable = () => {
    setVariables([...variables, { key: variables.length + 1, value: '' }]);
    setContent(content + `{{${variables.length + 1}}}`);
  };

  const handleVariableChange = (index, value) => {
    const newVariables = [...variables];
    newVariables[index].value = value;
    setVariables(newVariables);
  };

  const handleAddQuickReply = () => {
    setQuickReplies([...quickReplies, { title: '', id: '' }]);
  };

  const handleQuickReplyChange = (index, field, value) => {
    const newQuickReplies = [...quickReplies];
    newQuickReplies[index][field] = value;
    setQuickReplies(newQuickReplies);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationResults = validateTemplate(templateName, content);
    if (validationResults.length > 0) {
      setRejectionReasons(validationResults);
    } else {
        const templateData = {
            friendly_name: templateName,
            language: language,
            variables: Object.fromEntries(variables.map(v => [v.key, v.value])),
            content: content,
            quick_replies: quickReplies,
        };
        try {
            const response = await fetch(`${backend_base_url}/create-whatsapp-template`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify(templateData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if(data.error){
                throw new Error('Could not add');
            }
            alert(`Template created successfully! SID: ${data.sid}`);
            // Reset form
            setTemplateName('');
            setContent('');
            setLanguage('en');
            setVariables([]);
            setQuickReplies([]);
            onCreate()
        } catch (error) {
            console.error('Error creating template:', error);
            alert('Error creating template. Please try again.');
            onCreate()
        }
        setRejectionReasons([]);
    }
  };

  return (
    <div>
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="templateName">Template Name:</label>
        <input
          id="templateName"
          type="text"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          required
        />
      </div>

      <div>
        <label htmlFor="language">Language:</label>
        <input
          id="language"
          type="text"
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          required
          disabled
        />
      </div>

      <div>
        <label htmlFor="content">Content:</label>
        <AutoResizeTextarea
          id="content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
          style={{"width": '100%'}}
        />
      </div>

      <div>
        <button type="button" className='btn-dark' onClick={handleAddVariable}>Add Variable</button>
        {variables.map((variable, index) => (
          <div key={variable.key}>
            <label htmlFor={`var-${variable.key}`}>{`Variable {{${variable.key}}}:`}</label>
            <input
              id={`var-${variable.key}`}
              type="text"
              value={variable.value}
              onChange={(e) => handleVariableChange(index, e.target.value)}
              placeholder={`Sample value for {{${variable.key}}}`}
            />
          </div>
        ))}
      </div>

      <div>
        <button type="button" className='btn-dark' onClick={handleAddQuickReply}>Add Quick Reply</button>
        {quickReplies.map((reply, index) => (
          <div key={index}>
            <input
              type="text"
              value={reply.title}
              onChange={(e) => handleQuickReplyChange(index, 'title', e.target.value)}
              placeholder="Quick Reply Title"
            />
            <input
              type="text"
              value={reply.id}
              onChange={(e) => handleQuickReplyChange(index, 'id', e.target.value)}
              placeholder="Quick Reply ID"
            />
          </div>
        ))}
      </div>

      <button type="submit">Create Template</button>
    </form>
    {rejectionReasons.length > 0 && (
        <div className='bg-red'>
          <h3>Potential Rejection Reasons:</h3>
          <ul>
            {rejectionReasons.map((reason, index) => (
              <li key={index}>{reason}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CreateTemplateForm;