
export async function generateSignedUrl(supabase, fullUrl, expiresIn = 3600) {
  // Extract the bucket name and file path from the full 
  if (!fullUrl) return null;
  console.log('url now', fullUrl)

  try {
    const url = new URL(fullUrl);
    const pathParts = url.pathname.split('/');
    const publicIndex = pathParts.indexOf('public');
    
    if (publicIndex === -1 || publicIndex === pathParts.length - 1) {
      throw new Error('Invalid URL format');
    }

    const bucketName = pathParts[publicIndex + 1];
    const filePath = pathParts.slice(publicIndex + 2).join('/');

    console.log('Bucket:', bucketName);
    console.log('File path:', filePath);

    const { data, error } = await supabase
      .storage
      .from(bucketName)
      .createSignedUrl(filePath, 3600); // 1 hour expiry

    if (error) throw error;
    return data.signedUrl;
  } catch (error) {
    console.error('Error generating signed URL:', error);
    return null;
  }

}